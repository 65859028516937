import { createGlobalStyle } from 'styled-components';

import { device } from '../utils';
import 'normalize.css';

export default createGlobalStyle`
  body {
    font-family: 'Gotham', Helvetica, Arial, sans-serif;
    margin: 0;
  }

  html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 16px;
    /* font-weight: lighter; */
  }

  bold, strong {
    font-weight: bold;
  }

  a {
    outline: none;
  }

  * {
    -webkit-tap-highlight-color: rgba(0,0,0,0)!important;
  }

  h2 {
    font-size: 20px;
    color: #E0261D;
    line-height: 1em;
    margin: 20px 0 20px;
    text-transform: uppercase;
    font-weight: lighter;
  }

  h3 {
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 0.9em;
    margin: 10px 0;
    font-style: italic;
    font-weight: 900;
    text-transform: uppercase;

    @media ${device.laptop} {
      font-size: 30px;
    }

    @media ${device.laptopL} {
      font-size: 40px;
    }
  }
  
  a {
    color: #000;
  }

  p {
    line-height: 1.3em;
  }

  #gatsby-noscript {
    background: #000;
    width: 100%;
    display: block;
    padding: 10px;
    color: #FFF;
    text-align: center;
    font-weight: bold;
  }


  .mobile-only {
    @media ${device.tablet} {
      display: none!important;
    }
  }

  .hide-mobile {
    @media ${device.tabletDown} {
      display: none!important;
    }
  }

  .container {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    /* border: 1px solid red; */

    @media ${device.laptop} {
      padding: 0 20px;
    }
  }

  .bbox {
    display: inline-block;
    background: #000;
    padding: 7px;
    transform: skew(-15deg);

    &.title {
      padding: 7px 15px;
    }

    & > * {
      display: inline-block;
      transform: skew(15deg);
      color: #FFF;
      font-weight: 900;
      font-style: italic;
      text-transform: uppercase;
    }
  }

  [role="button"] {
    outline: none;
  }

  .row {
    display: flex;
    flex-direction: column;

    .col-1 {
      flex: 1;
    }

    .col-2 {
      flex: 2;
    }

    @media ${device.laptop} {
      flex-direction: row;

      [class*="col-"] {
        padding: 20px;

        &:first-child {
          padding-left:0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    @media ${device.laptopM} {
      [class*="col-"] {
        padding: 30px;
      }
    }
  }

  @keyframes heartbeat {
    0% {
      transform: scale( 1);
    }
    10% {
      transform: scale( 1.25);
    }
    20% {
      transform: scale( 1);
    }
    30% {
      transform: scale( 1.25);
    }
    40% {
      transform: scale( 1);
    }
    100% {
      transform: scale( 1);
    }
  }
`;
