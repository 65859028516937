// @flow

const size = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  latopM: 1200,
  laptopL: 1440,
  desktop: 2560
};

export const device = {
  mobileS: `(min-width: ${size.mobileS}px)`,
  mobileM: `(min-width: ${size.mobileM}px)`,
  mobileL: `(min-width: ${size.mobileL}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  laptop: `(min-width: ${size.laptop}px)`,
  laptopM: `(min-width: ${size.latopM}px)`,
  laptopL: `(min-width: ${size.laptopL}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  desktopL: `(min-width: ${size.desktop}px)`,
  mobileSDown: `(max-width: ${size.mobileS - 1}px)`,
  mobileMDown: `(max-width: ${size.mobileM - 1}px)`,
  mobileLDown: `(max-width: ${size.mobileL - 1}px)`,
  tabletDown: `(max-width: ${size.tablet - 1}px)`,
  laptopDown: `(max-width: ${size.laptop - 1}px)`,
  laptopLDown: `(max-width: ${size.laptopL - 1})px`,
  desktopDown: `(max-width: ${size.desktop - 1})px`,
  desktopLDown: `(max-width: ${size.desktop - 1})px`
};