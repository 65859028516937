import styled from 'styled-components';
import { device } from '../utils';

export const Wrapper = styled.footer`
  background: #000;
  padding: 30px 20px;

  color: #FFF;

  @media ${device.laptop} {
    padding: 50px 20px;
  }

  .mobile-legal {
    display: block;
    text-align: center;
    margin-bottom: 30px;
    @media ${device.laptop} {
      display: none;
    }
  }

  .top {
    margin-bottom: 40px;

    .menu {
      margin: 0 30px 0 0;
      padding: 0;
      display: none;

      @media ${device.laptop} {
        display: inline-block;
      }

      li {
        list-style: none;
        display: inline-block;
        vertical-align: bottom;
        font-weight: bold;
        font-style: italic;
        text-transform: uppercase;
        font-size: 14px;
        margin: 0 10px;
      }
    }

    .social {
      text-align: center;

      a {
        margin: 0 5px;
      }
    }

    @media ${device.laptop} {
      display: flex;
      margin-bottom: 80px;

      .social {
        margin-left: auto;
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  
    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      .legal {
        margin-top: 20px;
        text-align: center;
        font-size: 12px;
        &, a {
          color: #7A7A7A;
          text-decoration: none;
        }
      }
    }

    .baseline {
      
    }

    @media ${device.laptop} {
      display: flex;
      align-items: flex-end;
      flex-direction: row;

      .logo {
        flex-direction: row;
        margin-top: 0;

        .legal {
          margin-top: 0;
          text-align: left;
          margin-left: 30px;
        }
      }

      .baseline {
        margin-left: auto;
      }
    }
  }
`;