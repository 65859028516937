
import { useEffect, useRef } from 'react';
export { device } from './responsive';

export const rem = px => `${Math.round(px / 16 * 1000) / 1000}rem`;

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Se souvenir de la dernière fonction de rappel.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Configurer l’intervalle.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const scrollTo = (selector, offset) => {
  var element = document.querySelector(selector);
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition - offset;

  window.scrollTo({
       top: offsetPosition + window.scrollY,
       behavior: "smooth"
  });
}