import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';

import { Link as CLink } from './Typo';
import { scrollTo } from '../utils';
import { Icon, Instagram, Logo } from './icons/';
import Facebook from '../images/icons/facebook.svg';
import Youtube from '../images/icons/youtube.svg';

import {
  Wrapper,
} from './footer.styles';

const QUERY = graphql`
  query {
    logo: file(relativePath: { eq: "passion-first.png" }) {
      childImageSharp {
        fixed(quality: 80, height: 75, toFormat: JPG) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

const Footer = () => {
  const data = useStaticQuery(QUERY);

  return (
    <Wrapper>
      <div className="container">
        <div className="top">
          <ul className="menu">
            <li>
              <CLink
                className="white"
                onClick={() => scrollTo("#sec-event", 50)}
                noIcon
              >
                <FormattedMessage id="header.menu.event" />
              </CLink>
            </li>
            <li>
              <CLink
                className="white"
                onClick={() => scrollTo("#sec-agenda", 50)}
                noIcon
              >
                <FormattedMessage id="header.menu.agenda" />
              </CLink>
            </li>
            <li>
              <CLink
                className="white"
                onClick={() => scrollTo("#sec-infos", 50)}
                noIcon
              >
                <FormattedMessage id="header.menu.information" />
              </CLink>
            </li>
            <li>
              <CLink
                className="white"
                href="/fr/cgv"
                noIcon
              >
                <FormattedMessage id="footer.terms" />
              </CLink>
            </li>
            <li>
              <CLink
                className="white"
                href="mailto:info@iponedays.com"
                noIcon
              >
                <FormattedMessage id="header.menu.contact" defaultMessage="Contact" />
              </CLink>
            </li>
            
          </ul>
          <div className="mobile-legal">
            <CLink
              className="white"
              href="/fr/cgv"
              noIcon
            >
              <FormattedMessage id="footer.terms" />
            </CLink>
          </div>
          <div className="social">
            <a
              href="https://facebook.com/iponelube/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon src={Facebook} height="21px" />
            </a>
            <a
              href="https://www.youtube.com/user/iponelube"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon src={Youtube} height="21px" />
            </a>
            <a
              href="https://instagram.com/iponelube"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon src={Instagram} height="21px" />
            </a>
          </div>
        </div>
        <div className="bottom">
          <div className="logo">
            <Link
              to="/"
            >
              <Icon
                src={Logo}
                height="41px"
              />
            </Link>
            <div className="legal">
              © {new Date().getFullYear()} All rights reserved. Made by <a href="https://www.breakingweb.com" target="_blank" rel="noopener noreferrer">breaking_web</a>
            </div>
          </div>
          <div className="baseline">
            <Img
              fixed={data.logo.childImageSharp.fixed}
              loading="eager"
              imgStyle={{
                objectFit: 'contain',
                objectPosition: 'top center',
              }}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
