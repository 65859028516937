import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: inherit;
  padding-bottom: 5px;
  text-align: left;
  margin-right: 10px;

  .flag-select__btn {
    padding: 0 2px;
    color: #000;
    border: none;
    background: transparent;
    outline: none;

    &:after, &[aria-expanded="true"]:after {
      content: " ";
      width: 0;
      height: 0;
      display: inline-block;
      vertical-align: middle;
      margin-left: 0px;
    }

    &:after {
      border-top: 5px solid #000;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 0;
    }

    &[aria-expanded="true"]:after {
      border-top: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #000;
    }

    &:before {
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 999;
    }
  }

  .flag-select__option {
    cursor: pointer;
    padding: 0 8px 3px 8px;
    margin: 4px 0;
    white-space: nowrap;

    .flag-select__option--placeholder {
      height: inherit;
      width: inherit;
      display: inline-block;
      vertical-align: middle;
    }

    &:not(.flag-select__option--placeholder) {
      &:hover, &:focus {
        outline: none;
        background: #eaeaea;
      }
    }

    &.has-label {
      padding: 0 20px 3px 8px;
    }

    .flag-select__option__label {
      font-size: 13px;
      position: relative;
      padding-left: 9px;
    }

    .flag-select__option__icon {
      width: 1.3em;
      height: 1.3em;
      position: relative;
      top: 0.3em;
    }
  }

  .flag-select__options {
    position: absolute;
    z-index: 999999;
    border: 1px solid #bdbbbb;
    border-radius: 3px;
    background: #FFF;
    margin-top: 8px;
    padding: 8px 0;
    max-height: 160px;
    overflow: auto;

    &.to--left {
      right: 10px;
    }
  }

  .filterBox {
    width: 100%;

    input {
      width: 90%;
      margin: 0 4%;

      &:focus {
        outline: none
      }
    }
  }

  .hidden {
    display: none;
  }

  .no--focus {
    pointer-events: none;
  }
`;