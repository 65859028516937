import React from 'react';
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';
import { Icon, Arrow } from './icons';

export const RedTitle = styled.div`
  display: inline-block;
  font-weight: bold;
  font-style: italic;
  padding: 5px 5px 5px 0;

  text-transform: uppercase;
  font-size: 15px;
  color: #E0261D;
  text-decoration: none;
  vertical-align: bottom;
  cursor: pointer;

  transition: color 300ms ease-in;

  .icon {
    display: inline;
    padding-left: 10px;
    stroke: #E0261D;
    transition: stroke 300ms ease-in, transform 300ms ease-in;
  }

  &.medium {
    font-size: 18px;
  }

  &:hover {
    
    svg {
      transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
      transform: translateX(5px);
    }
  }

  &.black {
    color: #000;

    .icon * {
      stroke: #000;
    }
  }

  &.white {
    color: #FFF;
  }

  &.no-icon {
    .icon {
      display: none;
    }
  }

  &:not(.button) {
    position: relative;
    overflow: hidden;

    &:before {
      transform: translate3d(-105%, 0, 0);
      transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:after {
      transform: translate3d(105%, 0, 0);
      transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    }
    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  
    &:before, &:after {
      content: "";
      position: absolute;
      left: 0;
      z-index: 9;
      height: 1px;
      width: 100%;
      background-color: #E0261D;
    }

    &.black {
      &:before, &:after {
        background-color: #000;
      }
    }

    &.white {
      &:before, &:after {
        background-color: #FFF;
      }
    }

    &:hover {
      &:before, &:after {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &.button {
    background-color: #E0261D;
    color: #FFF;
    font-size: 15px;
    letter-spacing: -0.02em;
    padding: 8px 15px;
    transition: background-color 300ms ease-in;

    &.medium {
      font-size: 18px;
    }

    svg {
      stroke: #FFF;
    }

    &.skew {
      transform: skew(-15deg);

      .inner {
        transform: skew(15deg);
      }
    }

    &:hover {
      background-color: #BA2019;
    }

    &.dark {
      background-color: #000;
      color: #FFF;
      border: 1px solid #FFF;

      .icon * {
        stroke: #FFF;
      }
      
      &:hover {
        background-color: #282828;
      }
    }
  }
`;

export const Link = (props) => (
  <RedTitle
    className={`${props.className} cta`}
    href={props.href}
    onClick={props.onClick}
    as="a"
  >
    {props.children}
    {!props.noIcon ? (
      <Icon
        width="18px"
        src={Arrow}
      />
    ) : null}

  </RedTitle>
);

export const BuyTickets = injectIntl((props) => null);
//  (
//   <RedTitle
//     href={props.intl.formatMessage({ id: 'link' })}
//     target="_blank"
//     rel="noopener"
//     {...props}
//     className={`${props.className || ''} cta animb`}
//     as="a"
//   >
//     <div className="inner">
//       {props.children || <FormattedMessage id="common.buy" />}
//       <Icon
//         width="12px"
//         src={Arrow}
//       />
//     </div>
//   </RedTitle>
// ));