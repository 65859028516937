import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { FormattedMessage, useIntl, changeLocale } from 'gatsby-plugin-intl';
import ReactFlagsSelect from './locales';

import { BuyTickets, Link as CLink } from './Typo';
import { scrollTo } from '../utils';

import {
  Wrapper,
} from './header.styles';

const QUERY = graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(quality: 80, height: 120, toFormat: PNG) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`;

const Header = () => {
  const data = useStaticQuery(QUERY);
  const intl = useIntl();

  return (
    <Wrapper>
      <div className="container">
        <div className="logo">
          <h1>
            <Link
              to="/"
              alt="Ipone Days"
            >
              <Img
                fixed={data.logo.childImageSharp.fixed}
                loading="eager"
                imgStyle={{
                  objectFit: 'contain',
                  objectPosition: 'top center',
                }}
              />
            </Link>
          </h1>
        </div>
        <div className="dates" dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: 'common.dates' }) }} />
        <ul className="menu">
          <li>
            <CLink
              className="black"
              onClick={() => scrollTo("#sec-event", 140)}
              noIcon
            >
              <FormattedMessage id="header.menu.event" />
            </CLink>
          </li>
          <li>
            <CLink
              className="black"
              onClick={() => scrollTo("#sec-agenda", 140)}
              noIcon
            >
              <FormattedMessage id="header.menu.agenda" />
            </CLink>
          </li>
          <li>
            <CLink
              className="black"
              onClick={() => scrollTo("#sec-infos", 140)}
              noIcon
            >
              <FormattedMessage id="header.menu.information" />
            </CLink>
          </li>
          <li>
            <CLink
              className="black"
              href="mailto:info@iponedays.com"
              noIcon
            >
              <FormattedMessage id="header.menu.contact" defaultMessage="Contact" />
            </CLink>
          </li>
        </ul>
        <ReactFlagsSelect
          countries={["FR", "EN", "ES"]}
          customLabels={{ "FR": "FR", "EN": "EN", "ES": "ES" }}
          selectedSize={13}
          defaultCountry={intl.locale.toUpperCase()}
          onSelect={(locale) => changeLocale(locale.toLowerCase())}
        />
        <BuyTickets className="button" />
      </div>
    </Wrapper>
  );
};

export default Header;
