import styled from 'styled-components';

import { device } from '../utils';

export const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #FFF;

  @media ${device.laptopDown} {
    padding: 10px 15px;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 91px;
    overflow: visible;

    padding-left: 180px;
    padding-top: 10px;
    padding-right: 10px;
    /* justify-content: space-between; */

    @media ${device.laptopDown} {
      padding-left: 130px;
      min-height: 60px;
      padding-right: 0;
    }
  }

  .logo {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1000;

    h1 {
      margin: 0;
    }

    @media ${device.laptopDown} {
      top: 5px;
      left: 0;

      .gatsby-image-wrapper {
        width: 115px!important;
        height: 98px!important;
      }
    }
  }

  .dates {
    font-weight: 900;
    font-style: italic;
    text-transform: uppercase;
    font-size: 15px;
    max-width: 130px;

    @media ${device.tablet} {
      font-size: 17px;
      max-width: none;
    }

    @media ${device.laptopL} {
      font-size: 18px;
    }
  }

  .menu {
    padding: 0;
    display: none;

    li {
      list-style: none;
      display: inline-block;
      vertical-align: bottom;
      font-weight: bold;
      font-style: italic;
      text-transform: uppercase;
      margin: 0 10px;

      a {
        font-size: 13px;
      }
      /* line-height: 2em; */

    }

    @media ${device.laptopM} {
      display: inline-block;
      margin: 0 10px;

      li {
        a {
          font-size: 12px;
        }
      }
    }
    @media (min-width: 1300px) {
      margin: 0 30px;
      li {
        a {
          font-size: 13px;
        }
      }
    }
  }

  .flag-select {
    margin-left: auto;
    margin-right: 15px;

    @media ${device.laptopDown} {
      margin-right: 0!important;
    }
  }

  .cta {
    @media ${device.tabletDown} {
      display: none;
    }
  }
`;