import React from 'react';
import classnames from 'classnames';

export { default as Logo } from '../../images/logo.svg';
export { default as Instagram } from '../../images/icons/instagram.svg';
export { default as Arrow } from '../../images/icons/arrow.svg';

const extractSVGProps = (props) => ({
  width: props.width,
  height: props.height,
  className: classnames('icon', props.className),
  alt: props.alt,
});

export const Icon = (props) => (
  <svg viewBox={props.src.viewBox} {...extractSVGProps(props)}>
    <use xlinkHref={`#${props.src.id}`} />
  </svg>
);